import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { ResellerViewModel } from '@features/models/reseller.model';
import { Result } from '@shared/models/result.model';

@Injectable({ providedIn: 'root' })
export class PublicService {
  private url = `${environment.apis.linkroom}/public`;

  constructor(private http: HttpClient) {}

  getByHostname() {
    const headers = new HttpHeaders().set('Bypass-Interceptor', 'true');
    return this.http.get<Result<ResellerViewModel>>(`${this.url}/resellers`, { headers });
  }
}
