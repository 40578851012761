<div id="modal-select-organization" class="modal-select-organization" [@fadeInOut]="state">
  <div class="modal-layer-create-room"></div>
  <div class="modal-content-create-room">
    <div class="modal-header">
      <h2>{{ shouldTranslate ? (title | translate) : title }}</h2>
      <button type="button" class="close-modal-create-room" (click)="close()"></button>
    </div>

    <div class="modal-body">
      <form [formGroup]="formGroup" (ngSubmit)="select()">
        <div class="row-field-create-room">
          <select required formControlName="value">
            <option value="">--{{ 'common.select-option' | translate }}--</option>
            @for (option of options; track option.key) {
              <option [value]="option.key">
                {{ shouldTranslate ? (option.value | translate) : option.value }}
              </option>
            }
          </select>
        </div>

        <div class="modal-footer">
          <button type="button" class="modal-button-cancel-confirmation" (click)="close()">
            {{ 'common.cancel' | translate }}
          </button>

          <button type="submit" class="modal-button-save" [disabled]="loading">
            {{ (loading ? 'common.loading' : 'common.continue') | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
