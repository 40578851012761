import { Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-attendee-kicked',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './attendee-kicked.component.html',
  styleUrl: './attendee-kicked.component.css'
})
export class AttendeeKickedComponent {
  authService = inject(AuthService);

  ngOnInit() {
    this.authService.signOut();
  }
  
  redirectToHome() {
    location.assign('/');
  }
}
