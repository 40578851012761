<div class="row-head">
  <div class="column-sitetitle">
    <a [routerLink]="['/rooms']">
      @if (organizationService.currentOrganization) {
        <img [src]="organizationService.currentOrganization.logo" alt="Linkroom" />
      }
    </a>
  </div>
  <div class="row-button">
    <div class="column-list-menu">
      <a [routerLink]="['/rooms']" routerLinkActive="active">
        {{ 'nav-bar.rooms' | translate }}
      </a>

      @if (organizationService.organizationList.length > 0) {
        <a href="javascript:void(0)" (click)="galleryRedirect()">
          {{ 'nav-bar.gallery' | translate }}
        </a>
      }
    </div>

    <div
      id="column-menu-user"
      class="column-menu"
      [style.display]="showAccountOptions ? '' : 'none'"
    >
      <div class="row-datauser">
        <div class="avatar-datauser" style="background: var(--secondary-color)">
          <span>{{ getUserInitials() }}</span>
        </div>
        <div class="datauser">
          <h3>{{ getUserName() }}</h3>
          <span>{{ authService.user?.email }}</span>

          @if (hasLicense) {
            <small class="licensed-datauser" (click)="showProfilePermissions(true)">
              Verified License
            </small>
          }
        </div>
      </div>

      <div class="row-listmenu-username">
        <a href="javascript:void(0)" (click)="showSelfSettings = true" class="icon-username"
          ><i></i> {{ 'nav-bar.options.my-name' | translate }}</a
        >

        @if (organizationService.organizationList.length > 1) {
          <a (click)="promptOrganizationSelect()" class="icon-organization">
            <i></i> {{ 'nav-bar.options.organization' | translate }}
          </a>
        }

        @if (!isSaUser && organizationService.organizationList.length > 0) {
          <a (click)="goToAdmin()" class="icon-admin">
            <i></i> {{ 'nav-bar.options.admin' | translate }}
          </a>
        }

        @if (organizationService.organizationList.length > 0) {
          <a (click)="showPresentations(true)" class="icon-presentations">
            <i></i> {{ 'nav-bar.options.presentations' | translate }}
          </a>
        }

        <a (click)="showSelectLanguage()" class="icon-language">
          <i></i> {{ 'nav-bar.options.language' | translate }}
        </a>
        <a (click)="logout()" class="icon-logout">
          <i></i> {{ 'nav-bar.options.log-out' | translate }}
        </a>
      </div>
    </div>
    <div class="column-username toggleUserOptions" id="column-username">
      <a class="toggleUserOptions">
        <span class="username" (click)="toggleAccountOptions()">{{ getUserName() }}</span>
        <div
          class="avatar-username"
          style="background: var(--secondary-color)"
          (click)="toggleAccountOptions()"
        >
          <span class="toggleUserOptions">{{ getUserInitials() }}</span>

          @if (hasLicense) {
            <i class="licensed-username"></i>
          }
        </div>
      </a>
    </div>
  </div>
</div>

<div class="modal-field" id="presentations" style="display: none">
  <div class="container-modal-field">
    <div class="modal-layer-field"></div>
    <div class="modal-content-field" style="width: 50vw">
      <div class="modal-header-field">
        <div style="display: flex; align-items: center">
          <h2>{{ 'nav-bar.presentations.list.title' | translate }}</h2>

          <button
            class="presentations-add"
            (click)="showPresentations(false)"
            [routerLink]="['/presentation-management/new']"
          >
            {{ 'common.add' | translate }}
          </button>
        </div>

        <button class="close-modal-field" (click)="showPresentations(false)"></button>
      </div>
      <div class="modal-body-field scrolltable">
        <form autocomplete="off">
          <div class="row-field-table">
            <table>
              <thead>
                <tr>
                  <th>{{ 'common.name' | translate }}</th>
                  <th style="width: 100px">
                    {{ 'nav-bar.presentations.list.actions' | translate }}
                  </th>
                  <th>{{ 'common.shared' | translate }}</th>
                  <th style="width: 100px"></th>
                </tr>
              </thead>
              <tbody>
                @for (item of presentations; track item) {
                  <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.actions.length }}</td>
                    <!-- TODO: UNCOMMENT THIS WHEN THE SHARE FUNCTIONALITY IS WORKING -->
                    <td>
                      @if (item.recipients.length == 0) {
                        <span style="padding: 1em">{{ item.recipients.length }}</span>
                      } @else {
                        <a
                          href="javascript:void(0)"
                          (click)="displayRecipients(true, item)"
                          style="padding: 1em"
                        >
                          {{ item.recipients.length }}
                        </a>
                      }
                    </td>
                    <td>
                      <div class="row-button-modal-field">
                        <button
                          type="button"
                          class="modal-button-save-field"
                          (click)="showPresentations(false)"
                          [routerLink]="['/presentation-management', item._id]"
                        >
                          {{ 'common.edit' | translate }}
                        </button>
                        <!-- TODO: UNCOMMENT THIS WHEN THE SHARE FUNCTIONALITY IS WORKING -->
                        <!-- <button
                          type="button"
                          class="modal-button-save-field"
                          (click)="sharePresentations(item._id)"
                        >
                          {{ 'common.share' | translate }}
                        </button> -->
                      </div>
                    </td>
                  </tr>
                }
                @if (sharedPresentationsWithMe.length > 0) {
                  <tr>
                    <td colspan="5" style="text-align: center">
                      <h3 style="font-weight: bold">Shared with me</h3>
                    </td>
                  </tr>
                }

                @for (item of sharedPresentationsWithMe; track item; let i = $index) {
                  <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.actions.length }}</td>
                    <td></td>
                    <td>
                      <div class="row-button-modal-field">
                        <button
                          class="modal-button-save-field"
                          (click)="removeSharedPresentation(item, i)"
                        >
                          {{ 'common.remove' | translate }}
                        </button>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

@if (showPermissions) {
  <div class="modal-field">
    <div class="container-modal-field">
      <div class="modal-layer-field"></div>
      <div class="modal-content-field" style="width: 45vw">
        <div class="modal-header-field">
          <h2>{{ 'nav-bar.permissions.title' | translate }}</h2>
          <button
            type="button"
            class="close-modal-field"
            (click)="showProfilePermissions(false)"
          ></button>
        </div>
        <div class="modal-body-field scrolltable">
          <div class="row-field-table">
            <table>
              <thead>
                <tr>
                  <th>{{ 'common.name' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                @for (permission of permissions; track permission) {
                  <tr>
                    <td>{{ permission }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (showSelfSettings) {
  <app-user-settings (close)="showSelfSettings = false" [isInRoom]="false" [isCameraEnabled]="true">
  </app-user-settings>
}
