import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, of, switchMap } from 'rxjs';

import { ResellerViewModel } from '@features/models/reseller.model';
import { OrganizationService } from '@features/services/organization.service';
import { PublicService } from '@features/services/public.service';

export const resellerInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.headers.has('Bypass-Interceptor')) {
    const modifiedReq = req.clone({
      headers: req.headers.delete('Bypass-Interceptor')
    });
    return next(modifiedReq); // Skip interceptor logic
  } else {
    const organizationService = inject(OrganizationService);
    const publicService = inject(PublicService);

    const localStorageReseller = localStorage.getItem('reseller');

    let reseller$: Observable<string | null>;

    if (localStorageReseller) {
      reseller$ = of((JSON.parse(localStorageReseller) as ResellerViewModel)._id);
    } else {
      reseller$ = publicService.getByHostname().pipe(
        switchMap(d => {
          const resellerId = d.data?._id || null;
          if (resellerId) {
            localStorage.setItem('reseller', JSON.stringify(d.data));
          }
          return of(resellerId);
        })
      );
    }

    return reseller$.pipe(
      switchMap(resellerId => {
        let request = req;

        if (resellerId) {
          request = req.clone({ headers: req.headers.set('Linkroom-Reseller', resellerId) });
        }

        const currentOrganization = organizationService.currentOrganization;
        if (currentOrganization) {
          request = request.clone({
            headers: request.headers.set('Linkroom-Organization', currentOrganization._id)
          });
        }

        return next(request);
      })
    );
  }
};
