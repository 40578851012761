import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { from, switchMap } from 'rxjs';

import { AuthService } from '@core/services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  return from(authService.retrieveToken()).pipe(
    switchMap(token => {
      const request = token
        ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) })
        : req;

      return next(request);
    })
  );
};
