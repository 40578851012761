import { NgModule } from '@angular/core';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { AttendeeKickedComponent } from '@features/components/attendee-kicked/attendee-kicked.component';
import { DEVICE_SETTINGS_URL_PATH } from '@shared/utils/constants';

const redirectLoggedInToRooms = () => redirectLoggedInTo(['rooms']);
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./core/modules/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      ),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToRooms, title: 'Forgot password' }
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./core/modules/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      ),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToRooms, title: 'Reset password' }
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./core/modules/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToRooms, title: 'Sign in' }
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./core/modules/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToRooms, title: 'Sign up' }
  },
  {
    path: 'removed',
    component: AttendeeKickedComponent
  },
  {
    path: 'rooms',
    loadChildren: () =>
      import('./features/modules/room-list/room-list.module').then(m => m.RoomListModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, showLayout: true, title: 'Rooms' }
  },
  {
    path: ':roomId',
    loadChildren: () => import('./features/modules/room/room.module').then(m => m.RoomModule)
  },
  {
    path: 'presentation/:roomId',
    loadChildren: () => import('./features/modules/room/room.module').then(m => m.RoomModule)
  },
  {
    path: 'presentation-management/:presentationId',
    loadChildren: () =>
      import('./features/modules/presentation-management/presentation-management.module').then(
        m => m.PresentationManagementModule
      ),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, showLayout: true, title: 'Presentations' }
  },
  {
    path: DEVICE_SETTINGS_URL_PATH,
    loadChildren: () =>
      import('./features/modules/device-settings/device-settings.module').then(
        m => m.DeviceSettingsModule
      )
  },
  {
    path: `${DEVICE_SETTINGS_URL_PATH}/:roomId`,
    loadChildren: () =>
      import('./features/modules/device-settings/device-settings.module').then(
        m => m.DeviceSettingsModule
      )
  },

  { path: '**', redirectTo: 'rooms' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
