@if (showLayout) {
  <div id="components-room-list">
    <div class="row-room-list">
      <app-nav-bar></app-nav-bar>
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <router-outlet></router-outlet>
}

@if (!isOnline) {
  <app-loading-modal
    [title]="'common.network.lost-connection-title' | translate"
    [message]="'common.network.lost-connection-action' | translate"
    [loading]="false"
  ></app-loading-modal>
}
