import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { filter, lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { NetworkService } from '@core/services/network.service';
import { ResellerService } from '@core/services/reseller.service';
import { TitleService } from '@core/services/title.service';
import { environment } from '@environment';
import { PublicService } from '@features/services/public.service';
import { APP_LANGUAGES, DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS } from '@shared/utils/constants';
import { generateGUID } from '@shared/utils/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  showLayout = false;
  isOnline: boolean = true;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private resellerService: ResellerService,
    private titleService: TitleService,
    private publicService: PublicService,
    private authService: AuthService,
    private networkService: NetworkService
  ) {}

  private getData(route: ActivatedRouteSnapshot, key: string) {
    let data = route.data[key];
    if (route.firstChild) data = this.getData(route.firstChild, key) || data;
    return data;
  }

  private initRouting() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentRoute = this.router.routerState.snapshot.root;
      this.showLayout = this.getData(currentRoute, 'showLayout');
      this.titleService.setTitle(this.getData(currentRoute, 'title'));
    });
  }

  private initTranslate() {
    this.translate.addLangs(APP_LANGUAGES.map(l => l.key));
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    this.translate.use(DEFAULT_LANGUAGE);
  }

  private async initReseller() {
    const { data } = await lastValueFrom(this.publicService.getByHostname());
    this.resellerService.init(data);
  }

  async ngOnInit() {
    this.initRouting();
    this.initTranslate();

    this.networkService.isOnline.subscribe(status => {
      this.isOnline = status;
    });

   //  if (!location.toString().includes('presentation')) {
   //    this.authService.user$.subscribe(async data => {
   //      console.log(`===user====`);
   //      console.log(data);
   //      if (data) {
   //        if (!localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID)) {
   //          const clientSessionId = generateGUID();
   //          localStorage.setItem(LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID, clientSessionId);
   //          await this.authService.updateClientSessionId(
   //            this.authService.user!._id,
   //            clientSessionId
   //          );
   //        }

   //        this.authService.listenClientSessionIdUpdates(data._id).subscribe({
   //          next: (data: any) => {
   //            console.log(`===data====`);
   //            console.log(data);
   //            if (data) {
   //              const localClientSessionId = localStorage.getItem(
   //                LOCAL_STORAGE_KEYS.CLIENT_SESSION_ID
   //              );

   //              if (!localClientSessionId || data.clientSessionId !== localClientSessionId) {
   //                this.authService.signOut();
   //                localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_EXPIRED, 'session-expired');
   //                location.assign('/sign-in?');
   //              }
   //            }
   //          }
   //        });
   //      }
   //    });
   //  }

    await this.initReseller();
  }
}
